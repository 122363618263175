<div class="task-chat">
    <div class="container-allMessage" #taskMessages (scroll)="onScroll($event)">
        <ng-container *ngFor="let msg of allMessages;let i = index;">

            <div class="date-splitter" *ngIf="i==0 || !compareDates(msg)">
                <span>{{getDateString(msg)}}</span>
            </div>
            <div class="notOwnMessage">
                <div class="top-info-message">
                    <span class="username">{{getMessageOwnerName(msg)}}</span>
                    <span class="timestamp">{{msg.msgTimestamp | date :'shortTime'}}</span>
                </div>
                <div class="content-message">
                    <div class="subtaskMessage" *ngIf="msg.subTaskMessage">
                        <span class="comment-in-subtask">Comment in document review</span>
                        <div class="purple-bubble">
                            <div class="top">
                                <mat-icon (click)="setCurrentComment()" class="message-icon">message</mat-icon>
                                <span class="document-title">{{getDocumentName(msg)}}</span>
                            </div>
                            <div class="message-in-bubble" [ngClass]="{'deletedMessage' : isDeletedMessage(msg)}">
                                {{msg.subTaskMessage.text}}
                            </div>
                        </div>
                    </div>
                    <div class="taskMessage" *ngIf="msg.taskMessage">
                        {{msg.taskMessage.text}}
                    </div>
                </div>
            </div>
        </ng-container>
    </div>

    <div class="history-chat">
        <mat-form-field class="chat-area" appearance="outline">
            <textarea class="textarea" matInput placeholder="Write a message"
                    (keydown.enter)="sendMessage()"
                    [(ngModel)]="message"
                    [disabled]="busy">
            </textarea>
            <mat-progress-spinner *ngIf="busy" class="spinner" diameter="10" color="accent" mode="indeterminate"></mat-progress-spinner>
        </mat-form-field>
        <div class="send" *ngIf="!busy"><mat-icon class="notranslate send-icon" (click)="sendMessage()">send</mat-icon></div>
      </div>
</div>