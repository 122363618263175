<div class="history">
  <div class="history-header">
    <div class="refresh">
      <mat-progress-spinner color="accent" *ngIf="busy" diameter="20" class="spinner" mode="indeterminate"></mat-progress-spinner>
    </div>
  </div>

  <div class="history-content" #messages (scroll)="onScroll($event)">
    <div class="content-elements" #contentElements *ngFor="let element of filteredHistory(); let i = index;">

      <div class="element-info version" *ngIf="element.documentVersion" [ngClass]="{'selected': element.documentVersion.id === selectedVersionId, 'clickeable': !isReviewerMode()}" (click)="selectVersion(element.documentVersion)">
        <div class="element-date version" *ngIf="i==0 || !compareDates(element)">
          <span>{{element.timestampUtc | date: 'dd/MM/yyyy'}}</span>
        </div>
        <div class="tag">
          <div class="content">version <span>{{element.documentVersion.versionNumber}}</span></div>
          <div class="status">{{element.documentVersion.status}}</div>
          <div class="author">{{element.documentVersion.creator}}</div>
          <div class="timestamp">{{getTimestamp(element)}}</div>
        </div>
      </div>

      <div class="element-date" *ngIf="(i==0 || !compareDates(element))&& !element.documentVersion">
        <span>{{getDateString(element.subTaskMessage)}}</span>
      </div>

      <div class="element-info message" *ngIf="element.subTaskMessage">
        <div class="tag">
          <div class="author" *ngIf="element.subTaskMessage.user">{{element.subTaskMessage.user.username}}</div>
          <div class="author" *ngIf="element.subTaskMessage.reviewer">{{element.subTaskMessage.reviewer.name}}</div>
          <div class="time">{{element.subTaskMessage.timestampUtc | date : 'h:mm a'}}</div>
        </div>
        <div class="content" [ngClass]="{'deleted-comment' : isDeleteComment(element.subTaskMessage) , 'selected-comment' : currentComment && currentComment.id === element.subTaskMessage.id}">
          <mat-icon class="comment-icon notranslate" (click)="setCurrentComment(element.subTaskMessage)" matTooltip="Click to focus the comment" *ngIf="isComment(element.subTaskMessage) && !isDeleteComment(element.subTaskMessage)">comment</mat-icon>
          <mat-icon class="comment-deletion-icon notranslate" (click)="removeComment(element.subTaskMessage)" matTooltip="Remove this comments" *ngIf="isComment(element.subTaskMessage) && isOwnComment(element.subTaskMessage) && !isDeleteComment(element.subTaskMessage) && ( currentComment &&  (currentComment.id == element.subTaskMessage.id))">delete</mat-icon>
          <span class="comment-text">{{element.subTaskMessage.text}}</span>
        </div>
      </div>

    </div>
  </div>

  <div class="history-chat">
    <mat-form-field class="chat-area" appearance="outline">
      <textarea class="textarea" matInput placeholder="Write a message"
                (keydown)="keydown($event)"
                [(ngModel)]="comment"
                [disabled]="busy"></textarea>
    </mat-form-field>
    <div class="send"><mat-icon class="notranslate send-icon" (click)="send()">send</mat-icon></div>
  </div>

</div>
