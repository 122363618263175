import { Component, OnInit } from '@angular/core';
import { MatLegacyDialog as MatDialog, MatLegacyDialogRef as MatDialogRef } from '@angular/material/legacy-dialog';


import { AuthService,
         Identity,
         CompanyRole,
         FeedbackService,
         EventHubService,
         PermissionsService,
         UserAction,
         ConnectionService} from '../../../../../Packages/npm/moondesk-web/projects/moondesk-web-lib/src/public_api';
import { MoonDeskConfig } from '../../../../_shared/models/MoonDeskConfig';
import { NavigationService } from '../services/navigation.service';
import { MoonDeskConfigService } from '../services/moon-desk-config.service';
import { AcceptLegalsComponent } from './accept-legals/accept-legals.component';
import { environment } from 'src/environments/environment';
import { IllustratorConfigService } from '../services/illustrator-config.service';


@Component({
  selector: 'app-main-frame',
  templateUrl: './frame.component.html',
  styleUrls: ['./frame.component.scss']
})
export class FrameComponent implements OnInit
{
  hover: any;
  selectedIndex: 0 | 1 | 2 | 3 | 4 | 5 = 0;
  identity: Identity;
  loading: boolean = true;
  opened: boolean = false;

  extensionVersion: MoonDeskConfig;

  accountTooltip: string;
  identityComplete: boolean;

  showTasks: boolean;
  showSupport: boolean;

  connectionError: boolean;

  private idlePopup: MatDialogRef<any>;

  constructor(private dialog: MatDialog,
    private authService: AuthService,
    private navService: NavigationService,
    private feedBackService: FeedbackService,
    private eventHubService: EventHubService,
    private moonDeskConfigService: MoonDeskConfigService,
    private illConfigService: IllustratorConfigService,
    private permissionsService: PermissionsService,
    private connService: ConnectionService)
  {
    this.navService.navToMyLabels.subscribe(() => this.selectedIndex = 0);
    this.navService.navToImport.subscribe(() => this.selectedIndex = 1);
    this.navService.navToEdit.subscribe(() => this.selectedIndex = 2);
    this.navService.navToMyTasks.subscribe(() => this.selectedIndex = 3);
    this.navService.navToLibrary.subscribe(() => this.selectedIndex = 4);
    this.navService.navToRules.subscribe(() => this.selectedIndex = 5);

    this.eventHubService.openTutorial.subscribe(() => this.tutorial());
    this.eventHubService.toggleSideNav.subscribe(() => this.toggleSideNav());

    this.feedBackService.showSupportButton = true;

    // this.notificationHubService.companyUpdateChannelPushed.pipe(debounceTime(1000)).subscribe( ()=>
    // {
    //   this.feedBackService.notifyMessage('Your workspace has been updated');
    // });
    // this.sessionTimeoutService.sessionTimeout.subscribe((st: SessionTimeout) =>
    // {
    //   if(st.timeout === true)
    //   {
    //     this.logout();
    //   }
    //   else if(st.idle)
    //   {
    //     this.showIdle();
    //   }
    //   else
    //   {
    //     this.closeIdle();
    //   }
    // });
    // this.illService.DocumentChangedEvent.subscribe(() => this.sessionTimeoutService.resetIdle());
    // this.illService.DocumentSavedEvent.subscribe(() => this.sessionTimeoutService.resetIdle());
    // this.illService.SelectionChangedEvent.subscribe(() => this.sessionTimeoutService.resetIdle());

    try
    {
      this.eventHubService.loadHubSpotSupportWidget.subscribe(() =>
        {
          this.modifyHubSpotWidget();
        });
    }
    catch (err)
    {
      console.log('Error changing HubSpot widget style');
      console.log(err);
    }
  }

  async ngOnInit()
  {
    try
    {
      this.loading = true;

      await this.illConfigService.waitForInit();
      await this.authService.waitForInit();

      this.connectionError = false;
      await this.testConnection();
      this.authService.identityChanged.subscribe(id =>
      {
        this.updateIdentity(id);
      });
      const identity = this.authService.getCurrentIdentity();
      this.updateIdentity(identity);
      this.extensionVersion = (await this.moonDeskConfigService.getMoonDeskConfig());
      this.authService.usersApprovalMissing.subscribe(() => this.openAcceptLegalsComponent());
      this.authService.refreshTokenExpired
        .subscribe(() =>
        {
          this.logout();
        }
      );
    }
    catch (err)
    {
      this.identity = undefined;
    }
    finally
    {
      this.loading = false;
    }
  }

  async testConnection()
  {
    try
    {
      await this.connService.connectionTest(environment.backendUrl);
    }
    catch (ex)
    {
      this.connectionError = true;
      throw ex;
    }
  }

  private openAcceptLegalsComponent()
  {
    const dialogRef = this.dialog.open(AcceptLegalsComponent,
      {
        width: '350px',
        height: 'auto',
        disableClose: true
      }
    );

    dialogRef.afterClosed().subscribe(async() =>
    {
      try
      {
        await this.authService.acceptLegals();
      }
      catch (err)
      {
        console.log('Error accepting legals');
        console.log(err);
      }
    });
  }

  async logout()
  {
    await this.authService.signOut();
  }

  private updateIdentity(id: Identity)
  {
    this.identity = id;
    this.updateFeatureState();
    this.updateAccountTooltip();
    this.updateIdentityComplete();
  }

  private updateAccountTooltip()
  {
    if (!this.identity || !this.identity.user)
    {
      this.accountTooltip = 'No user signed in';
    }
    else
    {
      if (!this.identity.company)
      {
        this.accountTooltip = `Hello ${this.identity.user.username}, please select a company`;
      }
      else
      {
        this.accountTooltip =
          `Hello ${this.identity.user.username}, now you are ${this.getRoleName(this.identity.role)} for '${this.identity.company.name}'`;
        this.opened = false;
      }
    }
  }

  private updateIdentityComplete()
  {
    this.identityComplete = this.identity !== undefined &&
           this.identity.user !== undefined &&
           this.identity.company !== undefined;
  }

  private getRoleName(role: CompanyRole): string
  {
    switch (role)
    {
      case CompanyRole.none:
        return 'Noone';
      case CompanyRole.stakeholder:
        return 'Stakeholder';
      case CompanyRole.user:
        return 'User';
      case CompanyRole.admin:
        return 'Admin';
    }
  }

  hasIdentity()
  {
    if (!this.loading && this.identity && this.identity.user)
    {
      return true;
    }
  }

  hasPermissionToUploadDocs()
  {
    return this.permissionsService.currentUserHasPermissionTo(UserAction.uploadDocumentsFromExt);
  }

  async refresh()
  {
    if (this.loading)
    {
      return;
    }
    try
    {
      this.loading = true;
      await this.authService.refreshAssignments();
    }
    catch (err)
    {
      let msg = 'Error loading account';
      if (err.error)
      {
        msg = err.error;
      }
      this.feedBackService.notifyError(msg, err);
    }
    finally
    {
      this.loading = false;
    }
  }

  showTask(): boolean
  {
    if (this.identity?.company?.subscriptionPlan?.hasTaskModule)
    {
      return true;
    }
    else
    {
      return false;
    }
  }

  toggleSideNav()
  {
    this.opened = !this.opened;
  }

  goToMyLabels()
  {
    this.navService.goToMyLabels();
  }

  goToImport()
  {
    this.navService.goToImport(undefined);
  }

  goToEdit()
  {
    this.navService.goToEdit(undefined);
  }

  private updateFeatureState = async () =>
  {
    this.showTasks = true;
  };

  goToTasks()
  {
    this.navService.goToTasks();
  }

  goToLibrary()
  {
    this.navService.goToLibrary();
  }

  goToRules()
  {
    this.navService.goToRules();
  }

  settings()
  {
    this.navService.settings();
  }

  website()
  {
    this.navService.website();
  }

  support()
  {
    this.navService.support();
  }

  tutorial()
  {
    this.navService.tutorial();
  }

  private closeIdle()
  {
    if (this.idlePopup)
    {
      this.idlePopup.close();
      this.idlePopup = undefined;
    }
  }

  // private showIdle()
  // {
  //   if(this.idlePopup)
  //   {
  //     return;
  //   }
  //   this.idlePopup = this.dialog.open(IdlePopupComponent,
  //   {
  //     data: { service: this.sessionTimeoutService },
  //     disableClose: true
  //   });
  // }

  /**
   * Redefine HubSpot Widget Style ## DEPRECATED CODE | HubSpot is no more available on Extension
   */
  modifyHubSpotWidget()
  {
    if (!(<any>window).HubSpotConversations)
    {
      (<any>window).hsConversationsOnReady = [() =>
        {
          setTimeout(() =>
          {
            if (this.applyHubspotStyles() !== true)
            {
              setTimeout(() =>
              {
                this.applyHubspotStyles();
              }, 5000);
            }
          }, 2500);
        },
      ];
    }
  }

  private applyHubspotStyles(): boolean
  {
    try
    {
      const hsDiv = document.getElementById('hubspot-messages-iframe-container');
      const hsIframe = <any>(hsDiv.getElementsByTagName('iframe')[0]);

      const button = hsIframe.contentWindow.document.getElementsByClassName('widget-launcher')[0];
      button.style.width = '30px'; button.style.height = '30px';
      button.style.backgroundColor = 'rgba(44, 136, 236, 0.7)';

      const css = 'img{height:15px !important;} .InitialMessageBubble__StyleWrapper-eRtbPx{display:none !important}';
      const style = hsIframe.contentWindow.document.createElement('style');
      const head = hsIframe.contentWindow.document.getElementsByTagName('head')[0];
      head.appendChild(style);

      style.type = 'text/css';
      style.appendChild(document.createTextNode(css));
      return true;
    }
    catch (err)
    {
      console.log('Error getting DOM elements : ' + err);
    }
    return false;
  }

}
